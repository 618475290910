<template>
    <aside class="side-panel d-block card bg-white shadow-sm w-100 h-100">
        <slot></slot>
    </aside>
</template>

<script>
    export default {
        name: "SidePanel",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .side-panel {
        border-radius: 15px;
        overflow-y: auto;
    }
</style>
