<template>
    <li class="member-item list-group-item d-flex justify-content-between align-items-center pl-3">
        <img class="rounded-circle mr-3"
             v-bind:alt="account.name"
             v-bind:src="`${ apiUrl }/${ apiVersion }/contact/${ account.phone }/avatar`">
        <div class="flex-grow-1 text-right">
            <h5 class="font-weight-bolder mb-1">
                {{ account.pushname ? account.pushname : `+${ account.phone }` }}
            </h5>
            <p class="mb-0"
               v-if="account.pushname">
                +{{ account.phone }}
            </p>
        </div>
    </li>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
export default {
    name: "AccountItem",
    props: {
        "account": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,
        };
    },
    computed: {
        users () {
            return this.$store.state.users.users;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.member-item {
    .rounded-circle {
        width: 64px;
        height: 64px;
        object-fit: cover;
    }
}
</style>
