<template>
    <side-panel class="chat-members d-flex">
        <div class="position-sticky title d-flex p-3">
            <h3 class="font-weight-bolder flex-grow-1 mb-0">
                Members of {{ chat ? chat.name : "" }}
            </h3>
        </div>

        <div class="chat-members-body flex-grow-1 overflow-auto">
            <div class="p-2">
                <h1 class="h5 font-weight-bolder">
                    ⚧️ Gender Distribution
                </h1>
                <div class="progress"
                     style="height: 22px;">
                    <div class="progress-bar bg-primary"
                         style="width: 56%">
                        103 ♂️
                    </div>
                    <div class="progress-bar bg-danger"
                         style="width: 44%">
                        81 ♀️
                    </div>
                </div>
            </div>
            <ul class="list-group shadow-sm rounded-xl m-2">
                <li class="position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1">
                    <div class="font-weight-bolder">
                        Members ranked by activeness
                    </div>
                </li>
                <account-item v-for="member in members"
                              v-bind:key="member._id"
                              v-bind:account="member">
                </account-item>
            </ul>
        </div>
    </side-panel>
</template>

<script>
import SidePanel from "../SidePanel";
import AccountItem from "./ChatMembers/AccountItem";
export default {
    name: "ChatMembers",
    components: {
        AccountItem,
        SidePanel,
    },
    props: {
        "chatId": {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        chat () {
            return this.$store.state.whatsapp.chats
                .find(
                    ({ _id }) => _id === this.chatId
                );
        },

        members () {
            return this.chat ? this.chat.members : [];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.chat-members {

}

.chat-members-body {

}

.list-group-item {
    &:first-child {
        top: 0;
        z-index: 1;
    }
}
</style>
